import React from "react";
import { SEO } from "../components/SEO";
import { Charts } from "../components/Charts";
import type { HeadProps } from "gatsby";

const StatsPage = () => <Charts />;

StatsPage.hideLogo = true;

export default StatsPage;

export function Head({ location }: HeadProps) {
  return <SEO title="Stats" pathname={location.pathname} />;
}
