import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "moment";
import "chartjs-adapter-moment";
import { enUS } from "date-fns/locale";
import { default as EthDater } from "ethereum-block-by-date";
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartDataset,
  ChartOptions,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  ScatterDataPoint,
  SubTitle,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import {
  FetchStakingPoolStatsQuery,
  FetchStakingPoolStatsQueryVariables,
  useFetchStakingPoolStatsQuery,
} from "../graphql/thegraph/generated";
import { BigNumber, utils } from "ethers";
import { QueryObserverSuccessResult, useQueries, useQuery } from "react-query";
import { QueriesOptions } from "react-query/types/react/useQueries";
import { RingLoader } from "react-spinners";
import {
  CTSI_POS_POOL_ADDRESS,
  CTSI_POS_THEGRAPH_ENDPOINT,
  provider,
} from "../config";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  SubTitle,
  Tooltip,
  Filler
);

const poolStartTimestamp = 1634283407;

const generateChartOptions = ({
  title,
  subTitle,
}: {
  title: string;
  subTitle?: string;
}): ChartOptions<"line"> => ({
  responsive: true,
  resizeDelay: 1000,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: "time",
      ticks: {
        source: "data",
      },
      adapters: {
        date: { locale: enUS },
      },
    },
    y: {
      min: 0,
    },
  },
  plugins: {
    title: {
      display: true,
      text: title,
    },
    subtitle: {
      display: !!subTitle,
      text: subTitle,
    },
  },
});

const chartDataSetOptions: Partial<ChartDataset<"line">> = {
  cubicInterpolationMode: "monotone",
  showLine: false,
  borderColor: "white",
  borderWidth: 2,
  backgroundColor: "#2d3ad1",
  fill: {
    target: "origin",
    above: "rgba(200, 200, 200, 0.1)",
  },
};

const ChartModule = ({
  isLoading,
  isFailed,
  title,
  subTitle,
  data,
}: {
  isLoading: boolean;
  isFailed: boolean;
  title: string;
  subTitle?: string;
  data: ChartData<"line">;
}) => {
  return (
    <div className="charts__module">
      {(isLoading && <RingLoader color="#666" size={200} />) ||
        (isFailed && <span>Module failed to load. Please try again.</span>) || (
          <Line
            options={generateChartOptions({ title, subTitle })}
            data={data}
          />
        )}
    </div>
  );
};

export const Charts = () => {
  // states
  const [fakeLoading, setFakeLoading] = useState(true);
  const [stackingPoolQueriesMap, setStackingPoolQueriesMap] = useState<
    [...QueriesOptions<FetchStakingPoolStatsQuery[]>]
  >([]);

  // queries
  const blocksQuery = useQuery<EthDater.BlockResult[]>("blocks", async () => {
    const dater = new EthDater(provider);
    const today = new Date();
    const lastYear = new Date();
    lastYear.setFullYear(lastYear.getFullYear() - 1);
    const poolStartDate = new Date(poolStartTimestamp * 1000);
    const startDate = new Date(
      Math.max(poolStartDate.getTime(), lastYear.getTime())
    );
    return dater.getEvery("months", startDate, today);
  });
  const { data: blocks } = blocksQuery;
  const stackingPoolQueries = useQueries<FetchStakingPoolStatsQuery[]>(
    stackingPoolQueriesMap
  );
  const queries = [...stackingPoolQueries, blocksQuery];

  useEffect(() => {
    // wait for the menu to close down before displaying the charts, otherwise we get responsive bugs
    // also allows to show the loading animation.
    setTimeout(() => setFakeLoading(false), 1000);
  }, [setFakeLoading]);

  useEffect(() => {
    (async () => {
      if (!blocks) return;
      const stakingPoolQueriesMap: [
        ...QueriesOptions<FetchStakingPoolStatsQuery[]>
      ] = [];
      for (let i = 0; i < blocks.length; i++) {
        const variables: FetchStakingPoolStatsQueryVariables = {
          pool: CTSI_POS_POOL_ADDRESS,
          block: blocks[i].block,
        };
        stakingPoolQueriesMap.push({
          queryKey: ["fetchStakingPoolStats", variables],
          queryFn: () =>
            useFetchStakingPoolStatsQuery.fetcher(
              { endpoint: CTSI_POS_THEGRAPH_ENDPOINT },
              variables
            )(),
        });
      }
      setStackingPoolQueriesMap(stakingPoolQueriesMap);
    })();
  }, [blocks]);

  const isLoading =
    fakeLoading || queries.some((query) => query.isLoading || query.isFetching);
  const isFailed = queries.some((query) => query.isError);
  const showData = !isLoading && !isFailed;
  const timestamps = (blocks && blocks.map((block) => block.timestamp)) || [];

  const constructDatasets = (
    mapCallback: (
      value: QueryObserverSuccessResult<FetchStakingPoolStatsQuery>,
      index: number
    ) => number
  ): ChartDataset<"line">[] =>
    (showData && [
      {
        ...chartDataSetOptions,
        data: (
          stackingPoolQueries as QueryObserverSuccessResult<FetchStakingPoolStatsQuery>[]
        )
          .map(mapCallback)
          .map((number: number, index: number) => ({
            x: timestamps[index] * 1000,
            y: number,
          })),
      },
    ]) ||
    [];

  const usersChartData: ChartData<"line"> = {
    datasets: constructDatasets(
      (query) => query.data.stakingPool?.totalUsers || 0
    ),
  };
  const stakeChartData: ChartData<"line"> = {
    datasets: constructDatasets((query) =>
      parseInt(
        utils.formatEther(BigNumber.from(query.data.stakingPool?.amount || 0))
      )
    ),
  };
  const blocksChartData: ChartData<"line"> = {
    datasets: constructDatasets((query) => query.data.user?.totalBlocks || 0),
  };
  const rewardsChartData: ChartData<"line"> = {
    datasets: constructDatasets((query) =>
      parseInt(
        utils.formatEther(BigNumber.from(query.data.user?.totalReward || 0))
      )
    ),
  };
  const feeChartData: ChartData<"line"> = {
    datasets: constructDatasets(
      (query) => (query.data.stakingPool?.fee?.commission || 0) / 100
    ),
  };
  const accruedFeeChartData: ChartData<"line"> = {
    datasets: constructDatasets((query) =>
      parseInt(
        utils.formatEther(
          BigNumber.from(query.data.stakingPool?.totalCommission || 0)
        )
      )
    ),
  };
  const apyChartData: ChartData<"line"> = {
    datasets: constructDatasets((query, index) => {
      if (index === 0) return 0;
      const stakesData = stakeChartData.datasets[0].data;
      const rewardsData = rewardsChartData.datasets[0].data;
      const timestamp1 = timestamps[index - 1];
      const timestamp2 = timestamps[index];
      const stake1 = BigNumber.from(
        (stakesData[index - 1] as ScatterDataPoint).y
      );
      const stake2 = BigNumber.from((stakesData[index] as ScatterDataPoint).y);
      const rewards1 = BigNumber.from(
        (rewardsData[index - 1] as ScatterDataPoint).y
      );
      const rewards2 = BigNumber.from(
        (rewardsData[index] as ScatterDataPoint).y
      );
      const stake = stake2.add(stake1).div(2);
      const rewards = rewards2.sub(rewards1);
      const diffTime = timestamp2 - timestamp1;
      const secondsInYear = 365 * 24 * 60 * 60;
      const rewardsYear = rewards.mul(secondsInYear).div(diffTime);
      const apy = rewardsYear.mul(100).div(stake);
      return apy.toNumber();
    }),
  };
  return (
    <>
      <h2>Pool Statistics.</h2>
      <div className="charts">
        <ChartModule
          title="Pool Users"
          subTitle="Number of users with a staking balance greater than zero"
          data={usersChartData}
          isLoading={isLoading}
          isFailed={isFailed}
        />
        <ChartModule
          title="Pool Stake (CTSI)"
          subTitle="CTSI staked in the pool at each specific time"
          data={stakeChartData}
          isLoading={isLoading}
          isFailed={isFailed}
        />
        <ChartModule
          title="Produced Blocks"
          subTitle="Total number of blocks produced by the pool over time"
          data={blocksChartData}
          isLoading={isLoading}
          isFailed={isFailed}
        />
        <ChartModule
          title="Pool Rewards (CTSI)"
          subTitle="Total rewards produced by the pool over time"
          data={rewardsChartData}
          isLoading={isLoading}
          isFailed={isFailed}
        />
        <ChartModule
          title="Pool Fee (%)"
          subTitle="Configured pool commission over time"
          data={feeChartData}
          isLoading={isLoading}
          isFailed={isFailed}
        />
        <ChartModule
          title="Accrued Fee (CTSI)"
          subTitle="Total pool commission over time"
          data={accruedFeeChartData}
          isLoading={isLoading}
          isFailed={isFailed}
        />
        <ChartModule
          title="Computed APY (%)"
          subTitle="APY is computed from each interval's rewards and against each interval's mean stake"
          data={apyChartData}
          isLoading={isLoading}
          isFailed={isFailed}
        />
      </div>
    </>
  );
};
